.FeedbackList {
	display: flex;
	flex-direction: column;
	margin-top: 5rem;
}

@media screen and (max-width: 1200px) {
	.FeedbackList {
		display: none;
	}
}

.Feedbacks {
	width: 85%;
	background: white;
	border-radius: 0.7rem;
	padding: 1rem;
	gap: 1rem;
	display: flex;
	flex-direction: column;
	font-size: 13px;
	height: 100%;
	overflow-y: auto;
	position: relative;
	height: 100%;
}

.feedback {
	display: flex;
	gap: 0.5rem;
}

.feedback > img {
	width: 3.2rem;
	height: 3.2rem;
}

.noti > div > span:nth-of-type(1) {
	font-weight: bold;
}

.noti {
	flex-grow: 1;
}

.feedbackDescription {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 4;
	line-clamp: 4;
	-webkit-box-orient: vertical;
}
