.feed{
    flex:5.5;
    overflow: auto;
    width: 100%;
    flex-direction: column;
}

.feedWrapper{
    padding: 20px;
    overflow: auto;
}

@media screen and (max-width: 1200px) {
    .feed{
        flex:5.5;
        overflow: auto;
        width: calc(100% - (123px + 149px));
        height: calc(100% - (42px + 40px));
        position: fixed;
        flex-direction: column;
    }    
}

@media screen and (max-width: 768px)
{
    .feed{
        flex:5.5;
        overflow: auto;
        width: calc(100% - (160px + 265px));
        height: calc(100% - (50px + 40px));
        position: fixed;
        flex-direction: column;
    }    
}