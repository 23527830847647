:root {
  --yellow: linear-gradient(180deg, #F8D49A -146.42%, #FAD79D -46.42%);
  --orangeLinear: linear-gradient(135deg, #e48a4c, #ec5432);
  --orange: #e48a4c;
  --black: #242d49;
  --gray: #788097;
  --purple: linear-gradient(180deg, #BB67FF 0%, #C484F3 100%);
  --pink: #da4f8e;
  --glass: rgba(255, 255, 255, 0.54);
  --boxShadow: 0px 19px 60px rgb(0 0 0 / 8%);
  --smboxShadow: -79px 51px 60px rgba(0, 0, 0, 0.08);
  --activeItem: #f799a354;
}

* {
  -ms-overflow-style: none;
}

::-webkit-scrollbar{
  height: 10px;
  width: 0;
  background: white;
}
::-webkit-scrollbar-thumb:horizontal{
  background: gray;
  border-radius: 4px;
}

.App {
  /* padding: 0.5rem 3.5rem; */
  color: var(--black);
  background: linear-gradient(
    106.37deg,
    #ffe1bc 29.63%,
    #ffcfd1 51.55%,
    #f3c6f1 90.85%
  );
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Inter', sans-serif;
}

.AppGlass {
  display: grid;
  height: 97%;
  width: 97%;
  background: var(--glass);
  border-radius: 2rem;
  gap: 16px;
  grid-template-columns: 10rem 81rem;
  overflow: hidden;
}

@media screen and (max-width: 1200px) {
  .AppGlass{
    grid-template-columns: 11rem 60rem;
  } 
}
