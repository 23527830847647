.cp_tree-table {
	width: 100%;
	border: 1px solid rgba(224, 224, 224, 1);
	font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
	position: relative;
	overflow: auto;
}

.cp_tree-table_header {
	height: 56px !important;
	align-items: center;
	border-bottom: 1px solid rgba(224, 224, 224, 1);
	width: fit-content !important;
}

.cp_tree-table_header-cell {
	margin-left: 2rem;
	border-right: 1px #6a69696e solid;
	min-width: 200px;
}

.cp_tree-table_cell {
	display: flex;
	align-items: center;
	padding: 0 10px;
	min-width: 200px;
	min-height: 52px;
	max-height: 106px;
	margin: 0 1rem;
}

.cp_tree-table_row {
	height: 52px !important;
	border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.cp_tree-table_viewport .cp_tree-table_mover {
	overflow: auto !important;
	width: fit-content !important;
	position: relative !important;
}

.cp_tree-table_viewport > div {
	width: fit-content !important;
	position: initial !important;
	overflow: unset !important;
}

.cp_tree-table_viewport {
	position: relative !important;
	/* overflow: hidden !important; */
	width: fit-content !important;
	/* height: 100% !important; */
}

.without-children {
	padding-left: 40px !important;
}

.cp_tree-table_cell:nth-child(2) {
	min-width: 400px;
}

.cp_tree-table_header-cell:nth-child(2) {
	min-width: 400px;
}

.cp_tree-table_cell:nth-child(4){
	min-width: 100px;
}

.cp_tree-table_cell:nth-child(5){
	min-width: 100px;
}

.cp_tree-table_cell:nth-child(6){
	min-width: 100px;
}

.cp_tree-table_header-cell:nth-child(4){
	min-width: 100px;
}

.cp_tree-table_header-cell:nth-child(5){
	min-width: 100px;
}

.cp_tree-table_header-cell:nth-child(6){
	min-width: 100px;
}