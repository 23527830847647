.CompactCard {
	display: flex;
	flex: 1;
	height: 7rem;
	border-radius: 0.7rem;
	color: white;
	padding: 1rem;
	position: relative;
	cursor: pointer;
}
.CompactCard:hover {
	box-shadow: none !important;
}

/* radial bar */
.radialBar {
	flex: 1 1;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	gap: 1rem;
}
.CircularProgressbar {
	width: 4rem !important;
	overflow: visible;
}
.CircularProgressbar-path {
	stroke: white !important;
	stroke-width: 12px !important;
	filter: drop-shadow(2px 4px 6px white);
}
.CircularProgressbar-trail {
	display: none;
}
.CircularProgressbar-text {
	fill: white !important;
}

.radialBar > span {
	font-size: 17px;
	font-weight: bold;
}

/* sideDetail*/
.detail {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: space-between;
}

.detail > span:nth-child(2) {
	font-size: 22px;
	font-weight: bold;
}
.detail > span:nth-child(3) {
	font-size: 12px;
}

/* ExpandedCard */
.ExpandedCard {
	position: absolute;
	width: 60%;
	height: 70vh;
	z-index: 9;
	left: 13rem;
	border-radius: 1rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	padding: 1rem;
}
.ExpandedCard > span:nth-of-type(1) {
	color: white;
	font-size: 26px;
	font-weight: bold;
	text-shadow: 0px 0px 15px white;
	-webkit-user-select:none;
    -moz-user-select:none;
    user-select:none;
}
.ExpandedCard > span:nth-of-type(2) {
	color: rgb(236, 236, 236);
	font-size: 15px;
}

.chartContainer {
	width: 70%;
}

@media screen and (max-width: 1200px) {
	.ExpandedCard {
		top: 2rem;
		height: 45vh;
		left: 6rem;
	}
}

@media screen and (max-width: 768px) {
	.ExpandedCard {
		top: 8rem;
		height: 50%;
		left: 25px;
		width: 80%;
	}
}

.overflowDescription {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 4;
	line-clamp: 4;
	-webkit-box-orient: vertical;
}

.cardBackground {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	mix-blend-mode: overlay;
	border-radius: 0.7rem;
}
