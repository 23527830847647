.Main{
    height: 100vh;
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

@media screen and (max-width: 1200px) {
    .Main{
        justify-content:flex-start;
        margin-top: 0.75rem;
    }
}

@media screen and (max-width: 768px)
{
    .Main{
        align-items: center;
    }
}
