.avatar {
  border-radius: 50%;
  object-fit: cover;
  width: 250px;
  height: 250px;
}

.banner {
    position: absolute;
    height: 300px;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    margin: -10px;
}
